/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddAlert from "@material-ui/icons/AddAlert";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import CustomInput from "components/CustomInput/CustomInput.js";
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};


const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PushData() {
  
  const classes = useStyles();
  const handlePush = () => { 
    setIsProccessing(true);
    const data = {   
      serial_number:serial_number,
      'start_date': moment(startDate).format('YYYY-MM-DD HH:mm:00'), 'end_date': moment(endDate).format('YYYY-MM-DD HH:mm:59'),
    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'update_android_database', data, {
      headers: headers
    }).then(response => {
      setIsProccessing(false);
        if(response.data.successMsg)
        {
          setError(false);
            setSuccess(true);
            setSuccessMsg(response.data.successMsg);
        }
        if(response.data.errorMsg)
        {
          setSuccess(false);
          setError(true);
          setErrorMsg(response.data.errorMsg);
        }
    }).catch(error => {
      setSuccess(false);
      setError(true);
      setErrorMsg(error);
    });


  }
  React.useEffect(() => {
  
  });
  const [serial_number, setSerialNumber] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isProcessing, setIsProccessing] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };
  var permissionData = JSON.parse(localStorage.getItem("admin_permission"));
  if(permissionData.push_device_data == "0")
    {
      
        return (
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
                <CardHeader className="cardheader" color="warning">
                  <h4 className={classes.cardTitleWhite}>Push Data To Merchant Device</h4>
                </CardHeader>
    
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={12} md={4}></GridItem>
                    <center>
                      <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
    
                    </center>
                    <div id="transitInfo"></div>
                  </GridContainer>
                </CardBody>
    
              </Card>
            </GridItem>
          </GridContainer>);
      
    }

  if (isProcessing) { //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Push Data To Merchant Device</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Please Wait...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }

  return (
    <GridContainer>

    <Snackbar anchorOrigin={{ vertical: "top", horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      
      {/* 
        Display success message
      */}
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      
      
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Push Data To Merchant Device</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
            <GridContainer>        
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime" style={{marginTop:"20px"}} >
                    <div className={classes.startDateBtn} >
                    
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker   
                                
                    label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                      
                    />
                    </LocalizationProvider>
                    </div>
                  </GridItem>                
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}}>
                  <div className={classes.startDateBtn}>             
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker        
                    label="End Date"        
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                    </div>
                  </GridItem>
                  
                   
                  <GridItem xs={12} sm={12} md={4} id="pushDataSerialNumber"  style={{marginTop:"20px"}}>
                  <CustomInput
                      labelText="Serial Number"
                      id="m_id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      value={serial_number}
                      onChange={(e) => setSerialNumber(e.target.value)}
                    />
                  </GridItem>
                  </GridContainer>
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={12}  style={{marginTop:"20px"}}>
                    <Button style={{width:"31%",float:"right"}} id="PushDataSearchBtn" variant="contained" color="primary" onClick={handlePush} className={`${classes.button} ${classes.buttonSearch}`}>
                      Push
                    </Button>
                  </GridItem> 
                  </GridContainer>
                   
            </CardBody>
          </Card>
        </GridItem>
        </GridContainer>
  );
}
