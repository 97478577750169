/*
   @page table list page (merchant list)
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MUIDataTable from "mui-datatables";
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
/*
  @const Page styles
*/
const styles = {
  paper: { maxWidth: "calc(100% - 25%)", width: "calc(100% - 25%)" },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function merchant list page logic
*/
const useStyles = makeStyles(styles);
const doLogin = (adminEmployeeId,emailAddr,isActive) => {
  localStorage.setItem('is_active', isActive);
  const data = { email: emailAddr };
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
  axios.post(window.apiUrl + 'login_as_admin', data, {
    headers: headers
  }).then(response => {
    if (response.data.token) {
      localStorage.setItem('admin_email',emailAddr);
      localStorage.setItem('is_active',isActive);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('role', response.data.role);
      localStorage.setItem('group_id', response.data.group_id);
      localStorage.setItem('admin_permission',JSON.stringify(response.data.admin_permission));
      window.location.href = "/admin/dashboard";
      
      

    }
  }).catch(error => {
    return error;
  });

}

const options = {
  filterType: 'checkbox',
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  searchPlaceholder: 'Search using MID, Business Name, Email',
  searchOpen: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  searchAlwaysOpen: true,

};
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [employeeStatus, setEmployeeStatus] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [showVerfyConfirmBox, setShowVerifyConfirmBox] = useState(false);
  const [userId, setUserId] = useState(0);
  const [deleteDailog, setAdminEmployeeDeleteDailog] = useState(false);
  const [resetDailog, setResetDailog] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [locationId, setLocationId] = useState(0);

  const [filterMId, setFilterMId] = useState("");
  const [filterBusinessName, setFilterBusinessName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");

  const [openAdminSetting, setOpenAdminSetting] = React.useState(false);

  const [editAdminId, setEditAdminId] = React.useState(0);
  const [userFirstName, setUserFirstName] = React.useState("");
  const [userLastName, setUserLastName] = React.useState("");
  const [userTitle, setUserTitle] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userPhoneNo, setUserPhoneNo] = React.useState("");
  const [userGroup, setUserGroup] = React.useState([]);

  const [switchMerchantList, setSwitchMerchantList] = React.useState(true);
  const [switchLoginAsMerchant, setSwitchLoginAsMerchant] = React.useState(true);
  const [switchChangeMerchantId, setSwitchChangeMerchantId] = React.useState(true);
  const [switchChangeMerchantcategoryCode, setSwitchChangeMerchantCategoryCode] = React.useState(true);
  const [switchChangeBusinessName, setSwitchChangeBusinessName] = React.useState(true);
  const [switchChangeStreetAddress, setSwitchChangeStreetAddress] = React.useState(true);
  const [switchChangeCity, setSwitchChangeCity] = React.useState(true);
  const [switchChangeState, setSwitchChangeState] = React.useState(true);
  const [switchChangeZipCode, setSwitchChangeZipCode] = React.useState(true);
  const [switchChangeOfflinePushDays, setSwitchChangeOfflinePushDays] = React.useState(true);
  const [switchChangePhoneNo, setSwitchChangePhoneNo] = React.useState(true);
  const [switchChangeEmailAddress, setSwitchChangeEmailAddress] = React.useState(true);
  const [switchChangeSessionTimeOut, setSwitchChangeSessionTimeOut] = React.useState(true);
  const [switchChangePasscode, setSwitchChangePasscode] = React.useState(true);
  const [switchChangeWebsite, setSwitchChangeWebsite] = React.useState(true);
  const [switchChangeContactName, setSwitchChangeContactName] = React.useState(true);
  const [switchChangeProcessor , setSwitchChangeProcessor ] = React.useState(true);
  const [switchDeleteMerchantLocation, setSwitchDeleteMerchantLocation] = React.useState(true);
  const [switchActivateDeactivateMerchantLocation, setSwitchActivateDeactivateMerchantLocation] = React.useState(true);
  const [switchAddMerchant, setSwitchAddMerchant] = React.useState(true);
  const [switchAccessDeviceManager, setSwitchAccessDeviceManager] = React.useState(true);
  const [switchAddDevice, setSwitchAddDevice] = React.useState(true);
  const [switchActiveDeactiveDevice, setSwitchActiveDeactiveDevice] = React.useState(true);
  const [switchActiveToken, setSwitchActiveToken] = React.useState(true);
  const [switchDeleteDevice, setSwitchDeleteDevice] = React.useState(true);
  const [switchEditDevice, setSwitchEditDevice] = React.useState(true);
  const [switchAccessLogDetail, setSwitchAccessLogDetail] = React.useState(true);
  const [switchAccessNotification , setSwitchAccessNotification ] = React.useState(true);
  const [switchAccessPushData, setSwitchAccesspushData] = React.useState(true);
  const [switchAccessOpenBatch, setSwitchAccessOpenBatch] = React.useState(true);
  const [switchAccessDevTools, setSwitchAccessDevTools ] = React.useState(true);
  const [switchAccessChangeMerchantGroup, setSwitchAccessChangeMerchantGroup ] = React.useState(true);
// Admin Permission 
  const [switchAccessAdminEmployee, setSwitchAccessAdminEmployee ] = React.useState(false);
  const [switchAccessLoginAdminEmployee, setSwitchAccessLoginAdminEmployee ] = React.useState(false);
  const [switchAccessAddAdminEmployee, setSwitchAccessAddAdminEmployee ] = React.useState(false);
  const [switchAccessEditAdminEmployee, setSwitchAccessEditAdminEmployee ] = React.useState(false);
  const [switchAccessDeleteAdminEmployee, setSwitchAccessDeleteAdminEmployee ] = React.useState(false);
  const [switchAccessPasswordResetAdminEmployee, setSwitchAccessPasswordResetAdminEmployee ] = React.useState(false);
  const [switchAccessActiveDeactiveAdminEmployee, setSwitchAccessActiveDeactiveAdminEmployee ] = React.useState(false);
  const [switchIsSalesAgent, setSwitchIsSalesAgent ] = React.useState(false);
  const [switchAccessMassAction, setSwitchAccessMassAction ] = React.useState(false);

  
  const [permissionAddAdminEmp, setAddAdminEmployee ] = React.useState("0");
  
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  /*
    @function handle user active or deactive
  */
  const handleActiveUser = () => {
    setTableData(false);
    setShowConfirmBox(false);
    setSuccessMsg("");
    setSuccess(false);
    const data = { admin_employee_id: userId, is_active: (employeeStatus) ? 1 : 0 };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'update_admin_employee_status', data, {
      headers: headers
    }).then(response => {
      if (employeeStatus) {
        setSuccessMsg("Employee Activated Successfully!");
      } else {
        setSuccessMsg("Employee Deactivated Successfully!");
      }
      getAdminEmployeeList();
      setSuccess(true);
    }).catch(error => {
      getAdminEmployeeList();
      setError(true);
      setErrorMsg("Some Error Occur!!");
      return error;
    });


  }
  const sendResetPasswordEmail = () => {      
    
    const data = {email:userEmail};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'forgot_password', data, {
      headers: headers
    }).then(response => {       
      setUserEmail("");   
      setResetDailog(false);
      setSuccess(true);
      setSuccessMsg("Reset password link has been sent to your email.");
          
    }).catch(error => {
      setUserEmail("");     
      setResetDailog(false);  
      setError(true);
      setErrorMsg("Some Error Occurred!");
    
      return error;
    });
  }
  
  const clearAdminFormFied = () =>
  {
    setEditAdminId(0);
    setUserFirstName("");
    setUserLastName("");
    setUserTitle("");
    setUserEmail("");
    setUserPhoneNo("");
    setUserGroup([]);
    
    setSwitchMerchantList(true);
    setSwitchLoginAsMerchant(true);
    setSwitchChangeMerchantId(true);
    setSwitchChangeMerchantCategoryCode(true);
    setSwitchChangeBusinessName(true);
    setSwitchChangeStreetAddress(true);
    setSwitchChangeCity(true);
    setSwitchChangeState(true);
    setSwitchChangeZipCode(true);
    setSwitchChangeOfflinePushDays(true);
    setSwitchChangePhoneNo(true);
    setSwitchChangeEmailAddress(true);
    setSwitchChangeSessionTimeOut(true);
    setSwitchChangePasscode(true);
    setSwitchChangeWebsite(true);
    setSwitchChangeContactName(true);
    setSwitchChangeProcessor (true);
    setSwitchDeleteMerchantLocation(true);
    setSwitchActivateDeactivateMerchantLocation(true);
    setSwitchAddMerchant(true);
    setSwitchAccessDeviceManager(true);
    setSwitchAddDevice(true);
    setSwitchActiveDeactiveDevice(true);
    setSwitchActiveToken(true);
    setSwitchDeleteDevice(true);
    setSwitchEditDevice(true);
    setSwitchAccessLogDetail(true);
    setSwitchAccessNotification (true);    
    setSwitchAccessOpenBatch(true);

    
    setSwitchAccessDevTools(true);
    setSwitchAccessChangeMerchantGroup(true);
    setSwitchAccessNotification(true);
    setSwitchAccesspushData(true);

    setSwitchAccessAdminEmployee(false);
    setSwitchAccessLoginAdminEmployee(false);
    setSwitchAccessAddAdminEmployee(false);
    setSwitchAccessEditAdminEmployee(false);
    setSwitchAccessDeleteAdminEmployee(false);
    setSwitchAccessPasswordResetAdminEmployee(false)
    setSwitchAccessActiveDeactiveAdminEmployee(false);
    setSwitchIsSalesAgent(false);
    setSwitchAccessMassAction(false);
    
  }
const handleEditClick =  (eachAdminEmployee) => 
{        
          setEditAdminId(eachAdminEmployee.id);
          setUserFirstName(eachAdminEmployee.first_name);
          setUserLastName(eachAdminEmployee.last_name);
          setUserTitle(eachAdminEmployee.title);
          setUserEmail(eachAdminEmployee.email);
          setUserPhoneNo(eachAdminEmployee.phone_number);
          setUserGroup(eachAdminEmployee.group_id.split(","));
          
          const permissionArr = JSON.parse(eachAdminEmployee.permission);          
          setSwitchMerchantList((permissionArr.merchant_list == "1")?true:false);
          setSwitchLoginAsMerchant((permissionArr.login_as_merchant == "1")?true:false);
          setSwitchChangeMerchantId((permissionArr.mid == "1")?true:false);
          setSwitchChangeMerchantCategoryCode((permissionArr.mcc == "1")?true:false);
          setSwitchChangeBusinessName((permissionArr.bussiness_name == "1")?true:false);
          setSwitchChangeStreetAddress((permissionArr.street_address == "1")?true:false);
          setSwitchChangeCity((permissionArr.city == "1")?true:false);
          setSwitchChangeState((permissionArr.state == "1")?true:false);
          setSwitchChangeZipCode((permissionArr.zipcode == "1")?true:false);
          setSwitchChangeOfflinePushDays((permissionArr.push_data == "1")?true:false);
          setSwitchChangePhoneNo((permissionArr.phone_number == "1")?true:false);
          setSwitchChangeEmailAddress((permissionArr.email == "1")?true:false);
          setSwitchChangeSessionTimeOut((permissionArr.session_timeout == "1")?true:false);
          setSwitchChangePasscode((permissionArr.passcode == "1")?true:false);
          setSwitchChangeWebsite((permissionArr.website == "1")?true:false);
          setSwitchChangeContactName((permissionArr.contact_name == "1")?true:false);
          setSwitchChangeProcessor ((permissionArr.processor == "1")?true:false);
          setSwitchDeleteMerchantLocation((permissionArr.delete_location == "1")?true:false);
          setSwitchActivateDeactivateMerchantLocation((permissionArr.change_location_status == "1")?true:false);
          setSwitchAddMerchant((permissionArr.add_merchant == "1")?true:false);
          setSwitchAccessDeviceManager((permissionArr.access_device_manager == "1")?true:false);
          setSwitchAddDevice((permissionArr.add_device == "1")?true:false);
          setSwitchActiveDeactiveDevice((permissionArr.change_device_status == "1")?true:false);
          setSwitchActiveToken((permissionArr.change_token_status == "1")?true:false);
          setSwitchDeleteDevice((permissionArr.delete_device == "1")?true:false);
          setSwitchEditDevice((permissionArr.edit_device == "1")?true:false);
          setSwitchAccessLogDetail((permissionArr.access_log == "1")?true:false);          
          setSwitchAccessOpenBatch((permissionArr.access_open_batch == "1")?true:false);
          
          setSwitchAccessNotification ((permissionArr.notification == "1")?true:false);
          setSwitchAccesspushData((permissionArr.push_device_data == "1")?true:false);          
          setSwitchAccessDevTools((permissionArr.dev_tools == "1")?true:false);
          setSwitchAccessChangeMerchantGroup((permissionArr.change_merchant_group == "1")?true:false);

          setSwitchAccessAdminEmployee((permissionArr.admin_employee == "1")?true:false);
          setSwitchAccessLoginAdminEmployee((permissionArr.login_admin == "1")?true:false);
          setSwitchAccessAddAdminEmployee((permissionArr.add_admin_employee == "1")?true:false);
          setSwitchAccessEditAdminEmployee((permissionArr.edit_admin_employee == "1")?true:false);
          setSwitchAccessDeleteAdminEmployee((permissionArr.delete_admin_employee == "1")?true:false);
          setSwitchAccessPasswordResetAdminEmployee((permissionArr.password_reset_admin_employee == "1")?true:false)
          setSwitchAccessActiveDeactiveAdminEmployee((permissionArr.active_deactive_admin_employee == "1")?true:false);
          setSwitchIsSalesAgent((permissionArr.is_sales_agent == "1")?true:false);
          setSwitchAccessMassAction((permissionArr.mass_action == "1")?true:false);

          setOpenAdminSetting(true);
  
}

  /*
    @Function get merchant list
  */
  const getAdminEmployeeList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_admin_employee_list', data, {
      headers: headers
    }).then(response => {

      

        if(localStorage.getItem('admin_email') == "admin@magicpay.net")
        {
          var accessEdit = "1"
          var accessDelete = "1"
          var accessPasswordReset = "1";
          var accessActiveDeactive = "1";
          var accessLogin = "1";          
          
        }else{
          var permissionData = JSON.parse(localStorage.getItem("admin_permission"));       
          var accessEdit = permissionData.edit_admin_employee;
          var accessDelete = permissionData.delete_admin_employee;
          var accessPasswordReset = permissionData.password_reset_admin_employee;
          var accessActiveDeactive = permissionData.active_deactive_admin_employee;
          var accessLogin = permissionData.login_admin;
        }


      const userArr = [];
      response.data.forEach((eachAdminEmployee) => {

        console.log(eachAdminEmployee);

        userArr.push([eachAdminEmployee.id, eachAdminEmployee.first_name, eachAdminEmployee.last_name, eachAdminEmployee.title, eachAdminEmployee.email, eachAdminEmployee.group_name, eachAdminEmployee.created_at,
        <FormControlLabel
          control={<Switch checked={(eachAdminEmployee.is_active == 1) ? true : false}
          disabled={(accessActiveDeactive == "0") ?true:false}
            onChange={(e) => { (accessActiveDeactive == "1")?(setShowConfirmBox(true),setUserId(eachAdminEmployee.id),setEmployeeStatus(e.target.checked)):"" }}
            name="checkedB" color="secondary" />}
          label={(eachAdminEmployee.is_active == 1) ? "Active" : "Deactivated"} />,
          <div><center><button id={(accessPasswordReset == "0" || eachAdminEmployee.is_active == 0)?"btnDisableGrayAdd":"btnOrange"} onClick={()=>{(accessPasswordReset == "1" && eachAdminEmployee.is_active == 1)?(setUserEmail(eachAdminEmployee.email),setResetDailog(true)):""}} ><LockResetIcon ></LockResetIcon></button></center></div>,  
        <button id={(accessEdit == "0") ?"grayediticon":"greenediticon"} onClick={()=>{(accessEdit == "1")?handleEditClick(eachAdminEmployee):""}} style={{ backgroundColor: '#ffa726', color: 'green', border: 'none' }}><EditIcon ></EditIcon></button>,
        <Button id={(accessLogin == "0" || eachAdminEmployee.is_active == 0)?"btnDisableGrayAdd":"btnGreenAdd"}  color="warning" onClick={() => (accessLogin == "1" && eachAdminEmployee.is_active == 1)?doLogin(eachAdminEmployee.id, eachAdminEmployee.email,eachAdminEmployee.is_active):""}>Login</Button>,
        <button id={(accessDelete == "0") ?"graydeleteicon":"reddeleteicon"} style={{ backgroundColor: '#FF9800', color: '#FFFFFF', border: 'none' }} onClick={() => { (accessDelete == "1")?(setAdminEmployeeDeleteDailog(true),setUserId(eachAdminEmployee.id)):"" }} ><DeleteForeverIcon></DeleteForeverIcon></button>]);
      });
      setTableData(userArr);
    }).catch(error => {
      return error;
    });
  }


  /*
    @Function for delete merchant
  */
  const handleDeleteAdminEmployee = () => {
    const data = { admin_employee_id: userId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'delete_admin_employee', data, {
      headers: headers
    }).then(response => {
      setUserId(0);
      setAdminEmployeeDeleteDailog(false);
      if (response.data.error) {
        setErrorMsg("Merchant Not Delete!");
        setError(true);
      } else {
        setSuccessMsg("Merchant Deleted Successfully!");
        setSuccess(true);
                
      }
      getAdminEmployeeList();
    }).catch(error => {
      return error;
    });

  }

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
    setSuccessMsg("");
  };

  useEffect(() => {    
    getAdminEmployeeList();
    var permissionData = JSON.parse(localStorage.getItem("admin_permission"));       
    if(localStorage.getItem('admin_email') == "admin@magicpay.net")
    {
        setAddAdminEmployee("1");      
    }else{
      setAddAdminEmployee(permissionData.login_admin);            
    }       
        
    
  }, []);

  const columns = [
    {
      name: "ID",
      label: "ID",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "First Name",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Last Name",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "title",
      label: "title",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Group",
      label: "Group",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Created at",
      label: "Created at",
      options: {
        filter: true,
        sort: false,
      }
    },

    {
      name: "Active/Deactivated",
      label: "Active/Deactivated",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "Password Reset",
      label: "Password Reset",
      options: {
        filter: false,
        sort: false,
      }      
    },
    {
      name: "Edit",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
      }      
    },
    {
      name: "Login As User",
      label: "Login As User",
      options: {
        filter: false,
        sort: false,
      }      
    },
    {
      name: "Delete",
      label: "Delete",
      options: {
        filter: true,
        sort: false,
      }
    },
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setUserGroup(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleAdminEmployeeCreate = (e) =>
  {
    
    const permissionData = {
      merchant_list:(switchMerchantList)?"1":"0",
      login_as_merchant:(switchLoginAsMerchant)?"1":"0",
      mid:(switchChangeMerchantId)?"1":"0",
      mcc:(switchChangeMerchantcategoryCode)?"1":"0",
      bussiness_name:(switchChangeBusinessName)?"1":"0",
      street_address:(switchChangeStreetAddress)?"1":"0",
      city:(switchChangeCity)?"1":"0",
      state:(switchChangeState)?"1":"0",
      zipcode:(switchChangeZipCode)?"1":"0",
      push_data:(switchChangeOfflinePushDays)?"1":"0",
      phone_number:(switchChangePhoneNo)?"1":"0",
      email:(switchChangeEmailAddress)?"1":"0",
      session_timeout:(switchChangeSessionTimeOut)?"1":"0",
      passcode:(switchChangePasscode)?"1":"0",
      website:(switchChangeWebsite)?"1":"0",
      contact_name:(switchChangeContactName)?"1":"0",
      processor:(switchChangeProcessor)?"1":"0",
      change_location_status:(switchActivateDeactivateMerchantLocation)?"1":"0",
      delete_location:(switchDeleteMerchantLocation)?"1":"0",
      add_merchant:(switchAddMerchant)?"1":"0",
      access_device_manager:(switchAccessDeviceManager)?"1":"0",
      add_device:(switchAddDevice)?"1":"0",
      change_device_status:(switchActiveDeactiveDevice)?"1":"0",
      change_token_status:(switchActiveToken)?"1":"0",
      delete_device:(switchDeleteDevice)?"1":"0",
      edit_device:(switchEditDevice)?"1":"0",
      access_log:(switchAccessLogDetail)?"1":"0",
      access_open_batch:(switchAccessOpenBatch)?"1":"0",
      
      notification:(switchAccessNotification)?"1":"0",
      push_device_data:(switchAccessPushData)?"1":"0",
      dev_tools:(switchAccessDevTools)?"1":"0",
      admin_employee:(switchAccessAdminEmployee)?"1":"0",
      login_admin:(switchAccessLoginAdminEmployee)?"1":"0",
      add_admin_employee:(switchAccessAddAdminEmployee)?"1":"0",
      edit_admin_employee:(switchAccessEditAdminEmployee)?"1":"0",
      delete_admin_employee:(switchAccessDeleteAdminEmployee)?"1":"0",
      password_reset_admin_employee:(switchAccessPasswordResetAdminEmployee)?"1":"0",
      active_deactive_admin_employee:(switchAccessActiveDeactiveAdminEmployee)?"1":"0",
      change_merchant_group:(switchAccessChangeMerchantGroup)?"1":"0",
      is_sales_agent:(switchIsSalesAgent)?"1":"0", 
      mass_action:(switchAccessMassAction)?"1":"0", 
    };
    
    const data = {
      first_name:userFirstName,
      last_name:userLastName,
      title:userTitle,
      email:userEmail,
      phone_number:userPhoneNo,
      group_id:userGroup,
      permission:permissionData
    }
    if(editAdminId > 0)
    {
      data.id = editAdminId;
    }
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    setTableData(false);    
    axios.post(window.apiUrl + 'create_admin_employee', data, {
      headers: headers
    }).then(response => {
        if(response.data.error)
        {
          setErrorMsg(response.data.error);
          setError(true);
          
        }else if(response.data.update)
        {
          setSuccessMsg("Update successfully.");
          setSuccess(true);
          setOpenAdminSetting(false);
        }        
        else
        {
          setSuccessMsg("User has been created successfully.");
          setSuccess(true);      
          setOpenAdminSetting(false);          
          clearAdminFormFied();
        }
        getAdminEmployeeList();
    }).catch(error => {
      setErrorMsg("Some error occurred!");
      setError(true);
      getAdminEmployeeList();
      return error;
    });

  }

  var permissionData = JSON.parse(localStorage.getItem("admin_permission"));  
  if(permissionData.admin_employee == "0")
  {
    
      return (
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Users List</h4>
              </CardHeader>
  
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={12} md={4}></GridItem>
                  <center>
                    <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
  
                  </center>
                  <div id="transitInfo"></div>
                </GridContainer>
              </CardBody>
  
            </Card>
          </GridItem>
        </GridContainer>);
    
  }

  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          {/* 
            Display error message
          */}          
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Users List</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
  <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>

          {/* 
            Display success message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {successMsg}
            </Alert>
          </Snackbar>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Users List</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                <FormControl style={{ width: "100%", display: "none" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="MID"
                    value={filterMId}
                    onChange={(e) => { setFilterMId(e.target.value) }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                <FormControl style={{ width: "100%", display: "none" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Business Name"
                    value={filterBusinessName}
                    onChange={(e) => { setFilterBusinessName(e.target.value) }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                <FormControl style={{ width: "100%", display: "none" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Email"
                    value={filterEmail}
                    onChange={(e) => { setFilterEmail(e.target.value) }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                  {/* <div style={{ height: "25px",display:"none" }}>&nbsp;</div> */}
                  <FormControl style={{ width: '100%', marginTop: "-5px", display: "none" }} className={classes.popupHalfWidth} xs={12} sm={12} md={3}>
                    <Button onClick={getAdminEmployeeList} variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} className={classes.button}>
                      Search
                    </Button>
                  </FormControl>
                </center>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
                <Button
                  id={(permissionAddAdminEmp == "0")?"btnDisableGrayAdd":"btnGreenAdd"}
                  variant="contained"
                  color="primary"                  
                  className={classes.button}                  
                  onClick={() => { (permissionAddAdminEmp == "1" )?(clearAdminFormFied(),setOpenAdminSetting(true)):"" }}
                >+</Button>
              </GridItem>
            </GridContainer>

            {/* <Table
              tableHeadercolor="warning"
              tableHead={["ID","MID","Business Name", "Email", "Created at", "Login As Merchant", "Active/Deactivated", "Delete"]}
              tableData={tableData}
            /> */}
            <MUIDataTable
              id="newdatatable"
              // title={"Employee List"}
              data={tableData}
              columns={columns}
              options={options}
            />
          </CardBody>
        </Card>

        {/* style={{ maxWidth: 'calc(100% - 30%) !important', width: 'calc(100% - 30%) !important' }} */}
        <Dialog
          classes={{ paper: classes.paper }}
          open={openAdminSetting} onClose={() => { setOpenAdminSetting(false); }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(editAdminId > 0)?"Update": "Create" } User</DialogTitle>
          <DialogContent>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="First Name"
                    onChange={(e) => { setUserFirstName(e.target.value) }}
                    value={userFirstName}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Last Name"
                    onChange={(e) => { setUserLastName(e.target.value) }}
                    value={userLastName}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Title"
                    onChange={(e) => { setUserTitle(e.target.value) }}
                    value={userTitle}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Email Address"
                    onChange={(e) => { setUserEmail(e.target.value) }}
                    value={userEmail}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Phone Number"
                    onChange={(e) => { setUserPhoneNo(e.target.value) }}
                    value={userPhoneNo}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%",margin:"0px !important" }} sx={{ m: 0, width: "100%" }}>
                  {/* Amount to textfield */}
                  <InputLabel id="demo-simple-select-helper-label">Group</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select"
                    label="Group"
                    multiple
                    style={{ width: '100%' }}
                    value={userGroup}
                    onChange={(e) => {handleChange(e)}}
                    input={<OutlinedInput label="Group" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"1"}>MagicPay USA</MenuItem>
                    <MenuItem value={"2"}>MagicPay Puerto Rico</MenuItem>
                    <MenuItem value={"3"}>MagicPay US Virgin Islands</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <div style={{ clear: 'both' }}></div><div styles={{margin:50}}>&nbsp;</div> <Divider />
            <h5><b>Permissions</b></h5>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchMerchantList}
                onChange={(e) => setSwitchMerchantList(e.target.checked)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Merchant List
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                  checked={switchLoginAsMerchant}
                  onChange={(e) => setSwitchLoginAsMerchant(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Login as Merchant 
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                   checked={switchChangeMerchantId}
                  onChange={(e) => setSwitchChangeMerchantId(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Merchant ID 
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeMerchantcategoryCode}
                  onChange={(e) => setSwitchChangeMerchantCategoryCode(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Merchant Category Code
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeBusinessName}
                  onChange={(e) => setSwitchChangeBusinessName(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Business Name
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeStreetAddress}
                  onChange={(e) => setSwitchChangeStreetAddress(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Street Address 
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeCity}
                  onChange={(e) => setSwitchChangeCity(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change City
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeState}
                  onChange={(e) => setSwitchChangeState(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change State
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeZipCode}
                  onChange={(e) => setSwitchChangeZipCode(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Zip Code
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeOfflinePushDays}
                  onChange={(e) => setSwitchChangeOfflinePushDays(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Offline Push Days
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangePhoneNo}
                  onChange={(e) => setSwitchChangePhoneNo(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Phone Number
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeEmailAddress}
                  onChange={(e) => setSwitchChangeEmailAddress(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Email Address
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeSessionTimeOut}
                  onChange={(e) => setSwitchChangeSessionTimeOut(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Session Timeout
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangePasscode}
                  onChange={(e) => setSwitchChangePasscode(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Passcode
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeWebsite}
                  onChange={(e) => setSwitchChangeWebsite(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Website
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeContactName}
                  onChange={(e) => setSwitchChangeContactName(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Contact Name
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchChangeProcessor}
                  onChange={(e) => setSwitchChangeProcessor(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Processor
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchDeleteMerchantLocation}
                  onChange={(e) => setSwitchDeleteMerchantLocation(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Delete Merchant Location
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchActivateDeactivateMerchantLocation}
                  onChange={(e) => setSwitchActivateDeactivateMerchantLocation(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Activate/Deactivate Merchant Location
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAddMerchant}
                  onChange={(e) => setSwitchAddMerchant(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Add Merchant
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessDeviceManager}
                  onChange={(e) => setSwitchAccessDeviceManager(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Device Manager
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAddDevice}
                  onChange={(e) => setSwitchAddDevice(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Add Device
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchActiveDeactiveDevice}
                  onChange={(e) => setSwitchActiveDeactiveDevice(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Activate/Deactivate Device
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchActiveToken}
                  onChange={(e) => setSwitchActiveToken(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Activate Token
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchDeleteDevice}
                  onChange={(e) => setSwitchDeleteDevice(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Delete Device
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchEditDevice}
                  onChange={(e) => setSwitchEditDevice(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Edit Device
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessLogDetail}
                  onChange={(e) => setSwitchAccessLogDetail(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Log Detail
              </GridItem>            
              
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessOpenBatch}
                  onChange={(e) => setSwitchAccessOpenBatch(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Open Batches
              </GridItem>    
              
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessPushData}
                  onChange={(e) => setSwitchAccesspushData(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Push Data
              </GridItem>    
              
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessNotification}
                  onChange={(e) => setSwitchAccessNotification(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Notification
              </GridItem> 

              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessDevTools}
                  onChange={(e) => setSwitchAccessDevTools(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access DevTools
              </GridItem> 
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessAdminEmployee}
                  onChange={(e) => setSwitchAccessAdminEmployee(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Users
              </GridItem> 
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessLoginAdminEmployee}
                  onChange={(e) => setSwitchAccessLoginAdminEmployee(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Login as User
              </GridItem> 


         <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessAddAdminEmployee}
                  onChange={(e) => setSwitchAccessAddAdminEmployee(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Add User
              </GridItem> 

              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessEditAdminEmployee}
                  onChange={(e) => setSwitchAccessEditAdminEmployee(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Edit User
              </GridItem> 

              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessDeleteAdminEmployee}
                  onChange={(e) => setSwitchAccessDeleteAdminEmployee(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Delete User
              </GridItem> 


              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessPasswordResetAdminEmployee}
                  onChange={(e) => setSwitchAccessPasswordResetAdminEmployee(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Password Reset Of User
              </GridItem> 


              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessActiveDeactiveAdminEmployee}
                  onChange={(e) => setSwitchAccessActiveDeactiveAdminEmployee(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Active/Deactive User
              </GridItem> 


              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessChangeMerchantGroup}
                  onChange={(e) => setSwitchAccessChangeMerchantGroup(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Change Merchant Group
              </GridItem> 
              
              
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchIsSalesAgent}
                  onChange={(e) => setSwitchIsSalesAgent(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />User is a Sales Agent
              </GridItem> 
              
              <GridItem xs={12} sm={12} md={4}>
                <Switch
                checked={switchAccessMassAction}
                  onChange={(e) => setSwitchAccessMassAction(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />Access Mass Changes
              </GridItem> 

            </GridContainer>

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { clearAdminFormFied();setOpenAdminSetting(false); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenAdd" onClick={(e)=>{handleAdminEmployeeCreate(e)}}>
              Save
            </Button>
          </DialogActions>
        </Dialog>


        {/* 
          Delete activate or deactivate  popup start
        */}
        <Dialog
          open={showConfirmBox}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{(employeeStatus) ? "Are you sure you want to activate this user?" : "Are you sure you want to deactivate this user?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={() => { setUserId(0); setShowConfirmBox(false) }} color="warning">
              No
            </Button>
            <Button id="btnRedDelete" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={handleActiveUser} color="secondery">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* 
          Delete merchamt  popup start
        */}


        <Dialog
          open={deleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete this user?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setUserId(0); setAdminEmployeeDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteAdminEmployee} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

          {/* 
          Delete merchamt  popup start
        */}

{/* Reset password dailog */}
      <Dialog
          open={resetDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to reset password of this user?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setUserEmail(""); setResetDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={sendResetPasswordEmail} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

      </GridItem>
    </GridContainer>
  );
}
