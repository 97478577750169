/*
   @page user profile
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import FormControl from "@mui/material/FormControl";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@mui/material/Pagination";
import CustomTable from "components/Table/Table";
import Helper from "../../util/Helper";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import RemoveRedEyeRoundedIcon from "@material-ui/icons/RemoveRedEyeRounded";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { display } from "@mui/system";
import { fontSize } from "@mui/system";
import { fontWeight } from "@mui/system";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  datePickerTextBox: {
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#495057",
    width: "90%",
    height: "45px",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  hiddenContent: { display: "none" },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const [successMsg, setSuccessMsg] = useState("");

  const [Error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [showVoidBtn, setShowVoidBtn] = useState(false);
  const [showVoidReturnBtn, setShowVoidReturnBtn] = useState(false);
  const [showRefundBtn, setShowRefundBtn] = useState(false);
  const [showFullRefundBtn, setShowFullRefundBtn] = useState(false);
  const [showPartialRefundBtn, setShowPartialRefundBtn] = useState(false);
  const [showItemRefundBtn, setShowItemRefundBtn] = useState(false);
  const [refundBtnText, setRefundBtnText] = useState("Refund");

  const [transAmount, setTransAmount] = useState("");
  const [transAuthCode, setTransAuthCode] = useState("");
  const [transcardEntryType, setTransCardEntryType] = useState("");
  const [transCardExpMonth, setTransCardExpMonth] = useState("");
  const [transCardExpYear, setTransCardExpYear] = useState("");
  const [transCardNumber, setTransCardNumber] = useState("");
  const [transCardType, setTransCardType] = useState("");
  const [transCardTypeLogo, setTransCardTypeLogo] = useState("");
  const [transDeviceCreatedAt, setTransDeviceCreatedAt] = useState("");
  const [transEcrRefNum, setTransEcrRefNum] = useState("");
  const [transactionLongId, setTransactionLongId] = useState("");
  const [transPaymentMethod, setTransPaymentMethod] = useState("");
  const [transResponseCode, setTransResponseCode] = useState("");
  const [transType, setTransType] = useState("");
  const [transitAvsStatus, setTransitAvsStatus] = useState("");
  const [transitAvsStatusShort, setTransitAvsStatusShort] = useState("");
  const [transitAvsStatusLong, setTransitAvsStatusLong] = useState("");
  const [transitCvvStatus, setTransitCvvStatus] = useState("");
  const [transitCvvStatusShort, setTransitCvvStatusShort] = useState("");
  const [transitCvvStatusLong, setTransitCvvStatusLong] = useState("");

  const [openOrderName, setOpenOrderName] = useState("");
  const [openOrderCurrency, setOpenOrderCurrency] = useState("USD");
  const [openOrderAddress, setOpenOrderAddress] = useState("");
  const [openOrderCity, setOpenOrderCity] = useState("");
  const [openOrderState, setOpenOrderState] = useState("");
  const [openOrderZipCode, setOpenOrderZipCode] = useState("");
  const [openOrderCountry, setOpenOrderCountry] = useState("");
  const [openOrderMobileNo, setOpenOrderMobileNo] = useState("");
  const [openOrderEmail, setOpenOrderEmail] = useState("");

  const [businessName, setBusinessName] = useState("");
  const [processorName, setProcessorName] = useState("");
  const [ccBin, setCcbin] = useState("");

  const [voidTransactionId, setVoidTransactionId] = useState(0);
  const [openVoidPopup, setOpenVoidPopup] = useState(false);
  const [openReturnTypePopup, setOpenReturnTypePopup] = useState(false);
  const [transITTransId, setTransITTrans] = useState("");
  const [id, setId] = useState(0);
  const [serial_number, setSerialNumber] = useState("");
  const [returnEcrRefNo, setReturnEcrRefNo] = useState(0);
  const [partialRefundAmount, setPartialRefundAmount] = useState("");
  const [transactionTotalAmt, setTransactionTotalAmt] = useState(0.0);
  const [returnEnterAmt, setReturnEnterAmt] = useState(0.0);
  const [availableRefund, setAvailableRefund] = useState(0);
  const [tip, setTip] = useState(0);
  const [openReturnPopup, setOpenReturnPopup] = useState(false);
  const [openPartialReturnPopup, setOpenPartialReturnPopup] = useState(false);
  const [openItemRefundPopup, setOpenItemRefundPopup] = useState(false);
  const [orderItem, setOrderItem] = useState([]);
  const [retrunItemId, setReturnItemId] = useState([]);
  const [selectRefundAmt, setSelectRefundAmt] = useState(0.0);

  const [openSendReceipt, setOpenSendReceipt] = useState(false);
  const [openSMSReceipt, setOpenSMSReceipt] = useState(false);
  const [sendNumber, setSendNumber] = useState("");
  const [sendEmail, setSendEmail] = useState("");
  const [voidReturnPopup, setVoidReturnPopup] = useState(false);
  const [voidReturnTransId, setVoidReturnTransId] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [recLink, setRecpitLink] = useState("");
  const [token, setToken] = useState(false);
  const [ip, setIp] = useState("");

  // lastRefundType

  const history = useHistory();
  useEffect(() => {
    getTransactionDetails(history.location.state.transactionDetailId);
  }, []);

  const handelVoidTransaction = () => {
    setIsLoadingData(true);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    const data = {
      transaction_id: voidTransactionId,
      location_id: localStorage.getItem("location_id"),
      parent_id: localStorage.getItem("parent_id"),
    };
    axios
      .post(window.apiUrl + "void_trans_using_transit", data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.VoidResponse.status == "PASS") {
          setSuccess(true);
          setSuccessMsg("Transaction Voided Successfully.");
        } else {
          setError(true);
          setErrorMsg(response.data.VoidResponse.responseMessage);
        }
        setShowVoidBtn(false);
        setShowVoidReturnBtn(false);
        setShowRefundBtn(false);
        setShowFullRefundBtn(false);
        setShowPartialRefundBtn(false);
        setShowItemRefundBtn(false);
        getTransactionDetails(history.location.state.transactionDetailId);
      })
      .catch((error) => {
        return error;
      });
  };

  const getTransactionDetails = (transactionId) => {
    const data = { id: transactionId };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    axios
      .post(window.apiUrl + "get_transaction_detail", data, {
        headers: headers,
      })
      .then((response) => {
        setIp(response.data.transactionDetailsData.ip_address);
        setToken(response.data.transactionDetailsData.transaction_token);
        setId(response.data.transactionDetailsData.id);
        setSerialNumber(response.data.transactionDetailsData.serial_number);
        setTransactionTotalAmt(response.data.transactionDetailsData.amount);
        setTip(response.data.transactionDetailsData.tip_amt.replace(".", ""));
        setTransITTrans(response.data.transactionDetailsData.transaction_id);
        setReturnEcrRefNo(response.data.transactionDetailsData.ecrref_num);
        var amt =
          response.data.transactionDetailsData.amount -
          response.data.transactionDetailsData.refund_amt;
        setAvailableRefund(amt);
        setReturnEnterAmt(amt);
        setRecpitLink(
          window.apiUrl.replace("/api/", "/mail_receipt/") +
            response.data.transactionDetailsData.ecrref_num
        );
        if (response.data.transactionDetailsData.processor == "TSYS TransIT") {
          if (
            response.data.transactionDetailsData.batch_id == "0" &&
            response.data.transactionDetailsData.transaction_type == "RETURN" &&
            response.data.transactionDetailsData.is_void == "0" &&
            response.data.transactionDetailsData.response_code == "000000" &&
            response.data.transactionDetailsData.transaction_status == "2"
          ) {
            setShowVoidReturnBtn(true);
          }
          // alert(response.data.transactionDetailsData.batch_id +" = "+response.data.transactionDetailsData.transaction_type +" = "+response.data.transactionDetailsData.is_void+" = "+response.data.transactionDetailsData.response_code);
          if (
            response.data.transactionDetailsData.refund_amt == 0.0 &&
            response.data.transactionDetailsData.batch_id == "0" &&
            response.data.transactionDetailsData.transaction_type == "SALE" &&
            response.data.transactionDetailsData.is_void == "0" &&
            response.data.transactionDetailsData.response_code == "000000"
          ) {
            setShowVoidBtn(true);
          }
          if (
            response.data.transactionDetailsData.transaction_type == "SALE" &&
            response.data.transactionDetailsData.is_void == "0" &&
            response.data.transactionDetailsData.response_code == "000000"
          ) {
            setShowRefundBtn(true);
            if (
              response.data.transactionDetailsData.items === "Full Refund" ||
              amt == 0.0
            ) {
              setRefundBtnText("Refunded");
            } else if (
              response.data.transactionDetailsData.items === "Partial Refund"
            ) {
              setRefundBtnText("Partially Refunded");
              setShowPartialRefundBtn(true);
            } else if (
              response.data.transactionDetailsData.items === "refund_items"
            ) {
              setRefundBtnText("Item Refunded");
              setShowItemRefundBtn(true);
            } else {
              setShowFullRefundBtn(true);
              setShowItemRefundBtn(true);
              setShowPartialRefundBtn(true);
            }
          }
          if (response.data.transactionDetailsData.openOrderData) {
            setOpenOrderCurrency(
              response.data.transactionDetailsData.openOrderData.currency
            );
          }
          setTransactionLongId(
            response.data.transactionDetailsData.transaction_id
          );
        } else {
          setTransactionLongId(response.data.transactionDetailsData.ecrref_num);
        }

        setTransAmount(response.data.transactionDetailsData.amount);

        setTransAuthCode(response.data.transactionDetailsData.auth_code);
        setTransCardEntryType(
          response.data.transactionDetailsData.card_entry_type
        );
        setTransCardExpMonth(
          response.data.transactionDetailsData.card_exp_month
        );
        setTransCardExpYear(response.data.transactionDetailsData.card_exp_year);
        setTransCardNumber(response.data.transactionDetailsData.card_number);
        setTransCardType(response.data.transactionDetailsData.card_type);
        var cardTypeLogo = "";

        if (response.data.transactionDetailsData.card_type == "CASH") {
          cardTypeLogo = "/paymnet_card_icon/cashIcon4.png";
        } else if (response.data.transactionDetailsData.card_type == "VISA") {
          cardTypeLogo = "/paymnet_card_icon/newVisaLogo.png";
        } else if (
          response.data.transactionDetailsData.card_type == "MASTERCARD"
        ) {
          cardTypeLogo = "/paymnet_card_icon/master_card.png";
        } else if (
          response.data.transactionDetailsData.card_type == "DISCOVER"
        ) {
          cardTypeLogo = "/paymnet_card_icon/discover.png";
        } else if (
          response.data.transactionDetailsData.card_type == "CHINA UNIONPAY" ||
          response.data.transactionDetailsData.card_type == "CHINAUNIONPAY"
        ) {
          cardTypeLogo = "/paymnet_card_icon/unionPay.png";
        } else if (response.data.transactionDetailsData.card_type == "DINERS") {
          cardTypeLogo = "/paymnet_card_icon/master_card.png";
        } else if (
          response.data.transactionDetailsData.card_type ==
            "AMERICAN EXPRESS" ||
          response.data.transactionDetailsData.card_type == "AMEX"
        ) {
          cardTypeLogo = "/paymnet_card_icon/american_express.png";
        } else if (response.data.transactionDetailsData.card_type == "ATH") {
          cardTypeLogo = "/paymnet_card_icon/ath.png";
        } else if (
          response.data.transactionDetailsData.card_type == "ATH MOBIL" ||
          response.data.transactionDetailsData.card_type == "ATH MOVIL"
        ) {
          cardTypeLogo = "/paymnet_card_icon/ath_movil.png";
        } else if (response.data.transactionDetailsData.card_type == "EBT") {
          cardTypeLogo = "/paymnet_card_icon/ebt.png";
        } else if (response.data.transactionDetailsData.card_type == "JCB") {
          cardTypeLogo = "/paymnet_card_icon/jcb.png";
        }
        setTransCardTypeLogo(cardTypeLogo);
        var t = new Date(response.data.transactionDetailsData.device_created_at);
        
        setTransDeviceCreatedAt(
          parseInt(t.getMonth() + 1) +"/" +t.getDate() +"/" + t.getFullYear()+" "+t.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })  
        );
        setTransEcrRefNum(response.data.transactionDetailsData.ecrref_num);
        setTransPaymentMethod(
          response.data.transactionDetailsData.payment_method
        );
        setTransResponseCode(
          response.data.transactionDetailsData.response_code
        );
        setTransType(response.data.transactionDetailsData.transaction_type);
        setTransitAvsStatus(
          response.data.transactionDetailsData.transit_avs_status
        );
        setTransitCvvStatus(
          response.data.transactionDetailsData.transit_cvv_status
        );
        setTransitAvsStatusShort(
          response.data.transactionDetailsData.transit_avs_status_short_text
        );
        setTransitAvsStatusLong(
          response.data.transactionDetailsData.transit_avs_status_long_text
        );
        setTransitCvvStatusShort(
          response.data.transactionDetailsData.transit_cvv_status_short_text
        );
        setTransitCvvStatusLong(
          response.data.transactionDetailsData.transit_cvv_status_long_text
        );
        setCcbin(response.data.transactionDetailsData.card_bin);
        setOpenOrderName(response.data.transactionDetailsData.card_holder_name);
        if (response.data.transactionDetailsData.openOrderData) {
          setOpenOrderAddress(
            response.data.transactionDetailsData.openOrderData.address
          );
          setOpenOrderCity(
            response.data.transactionDetailsData.openOrderData.city
          );
          setOpenOrderState(
            response.data.transactionDetailsData.openOrderData.state
          );
          setOpenOrderZipCode(
            response.data.transactionDetailsData.openOrderData.zipcode
          );
          setOpenOrderCountry(
            response.data.transactionDetailsData.openOrderData.country
          );
          setOpenOrderMobileNo(
            response.data.transactionDetailsData.openOrderData.phone
          );
          setOpenOrderEmail(
            response.data.transactionDetailsData.openOrderData.email
          );
        }
        setBusinessName(response.data.transactionDetailsData.business_name);
        setProcessorName(response.data.transactionDetailsData.processor);

        setIsLoadingData(false);
      });
  };

  const handelReturnTransaction = (refund_type, returnAmt) => {
    setIsLoadingData(true);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };

    selectRefundAmt;
    const data = {
      refund_type: refund_type,
      id: id,
      serial_number: serial_number,
      parent_id: localStorage.getItem("parent_id"),
      location_id: localStorage.getItem("location_id"),
      transaction_id: transITTransId,
      amount: returnAmt.replace(".", ""),
      tip: tip,
    };
    if (refund_type === "refund_items") {
      console.log("iiiiiiiiiiiiii" + retrunItemId + "iiiii" + selectRefundAmt);
      data.refund_items_id = retrunItemId;
      data.amount = String(selectRefundAmt.toFixed(2)).replace(".", "");
    }
    axios
      .post(window.apiUrl + "return_trans_using_transit", data, {
        headers: headers,
      })
      .then((response) => {
        // console.log(response.data);
        // alert(JSON.stringify(response.data));

        if (response.data.status !== "PASS") {
          setError(true);
          setErrorMsg(response.data.responseMessage);
        } else {
          setSuccess(true);
          setSuccessMsg("Refunded Successfully.");
        }
        // location.reload();
        setShowVoidBtn(false);
        setShowVoidReturnBtn(false);
        setShowRefundBtn(false);
        setShowFullRefundBtn(false);
        setShowPartialRefundBtn(false);
        setShowItemRefundBtn(false);
        getTransactionDetails(history.location.state.transactionDetailId);
      })
      .catch((error) => {
        return error;
      });
  };

  /*
    @function get Item listing for selection of refund
  */
  const getTransactionItemList = () => {
    var loadingHtml = [];
    loadingHtml.push(
      <div style={{ textAlign: "center", width: "100%" }}>
        <CircularProgress id="loginProgress"></CircularProgress>
      </div>
    );
    setOrderItem(loadingHtml);

    const data = {};
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.ecrref_num = returnEcrRefNo;
    axios
      .post(window.apiUrl + "item_wise_refund_with_calculated_price", data, {
        headers: headers,
      })
      .then((response) => {
        var itemHtml = [];
        var totalItem = 0.0;
        var itemIds = [];
        response.data.orderItems.forEach((eachItems) => {
          console.log("&&&&&&&&&");
          console.log(eachItems.id);

          itemHtml.push(
            <div style={{ width: "100%", display: "flex" }} key={eachItems.id}>
              <div style={{ flexGrow: "1" }}>
                <FormControlLabel
                  id="refundItemCheckBox"
                  control={
                    <Checkbox
                      onChange={(e) => {
                        itemIds.push(eachItems.id);
                        setReturnItemId(itemIds);
                        totalItem += parseFloat(e.target.value);
                        setSelectRefundAmt(totalItem);
                      }}
                    />
                  }
                  value={eachItems.price}
                  label={eachItems.name}
                />
              </div>
              <div style={{ paddingTop: "8px" }}>
                <span style={{ fontWeight: "400" }}>${eachItems.price}</span>
              </div>
            </div>
          );
        });
        setOrderItem(itemHtml);
      })
      .catch((error) => {
        return error;
      });
  };

  /*
    @function Sent receipt in email
  */
  const sendEmailReceipt = () => {
    const data = {
      receipt_email_address: sendEmail,
      ecr_ref_no: returnEcrRefNo,
      total_amount: transAmount,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "send_receipt_email", data, {
        headers: headers,
      })
      .then((response) => {
        setOpenSendReceipt(false);
        setSendEmail("");
        if (response.data.msg === "success") {
          //Check receipt was sent in email successfully
          setSuccess(true);
          setSuccessMsg("Receipt Sent Successfully!");
        } else {
          setError(true);
          setErrorMsg("Some Error Occur,Receipt Not Sent!!");
        }
      })
      .catch((error) => {
        return error;
      });
  };

  /*
    @function Sent receipt in sms
  */
  const sendSMSReceipt = () => {
    const data = {
      number: sendNumber,
      ecrRefNum: returnEcrRefNo,
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "sms", data, {
        headers: headers,
      })
      .then((response) => {
        setOpenSMSReceipt(false);
        setSendNumber("");
        setSuccess(true);
        setSuccessMsg("SMS Send Successfully!");
      })
      .catch((error) => {
        setError(true);
        setErrorMsg(error.response.data.msg);
        return error;
      });
  };

  const validatePartialRefundValue = (e) => {
    var val = e.target.value;
    // if (val.length < 6) {
    var per = val.replace(".", "");
    per = per / 100;
    if (!isNaN(per)) {
      setReturnEnterAmt(parseFloat(per).toFixed(2));
    }
    // }
  };

  /*
    @function Download receipt in pdf format
  */
  const downloadPdf = (ecrNum) => {
    const data = {
      ECRRefNum: ecrNum,
      IsWebPDF: "1",
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .get(window.apiUrl + "mail_receipt/" + ecrNum + "?IsWebPDF=1", data, {
        headers: headers,
      })
      .then((response) => {
        let htmlStr = response.data;

        htmlStr = htmlStr.replace(
          '<body style="background-color:white;">',
          '<body style="background-color:white;"><div style="word-spacing: 3px;">'
        );
        htmlStr = htmlStr.replace("</body>", "</div></body>");

        var html = htmlToPdfmake(htmlStr);

        const documentDefinition = { content: html };
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake
          .createPdf(documentDefinition)
          .download("receipt_" + ecrNum + ".pdf");
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setErrorMsg("Fail to download");
        return error;
      });
  };
  const showCardHistory = (e) => {
    e.preventDefault();
    history.push("/card_history", { token: token });
  };
  const voidReturnTransIt = (voidReturnTransactionId) => {
    setIsLoadingData(true);
    const data = {
      transaction_id: voidReturnTransactionId,
    };

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    data.parent_id = localStorage.getItem("parent_id");
    data.location_id = localStorage.getItem("location_id");
    axios
      .post(window.apiUrl + "void_return_using_transit", data, {
        headers: headers,
      })
      .then((response) => {
        setSuccess(true);
        setSuccessMsg("Return Voided Successfully.");
        setShowVoidBtn(false);
        setShowVoidReturnBtn(false);
        setShowRefundBtn(false);
        setShowFullRefundBtn(false);
        setShowPartialRefundBtn(false);
        setShowItemRefundBtn(false);
        getTransactionDetails(history.location.state.transactionDetailId);
      })
      .catch((error) => {
        setError(true);
        setErrorMsg("Void return failed.");
        return error;
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  if (isLoadingData) {
    //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Transaction Details</h4>
              <p className={classes.cardCategoryWhite}>
                {/* Here is a subtitle for this table */}
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress"></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Error}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={sucess}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="success">{successMsg}</Alert>
      </Snackbar>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            variant="contained"
            id="voidBtn"
            style={{
              textTransform: "none",
              display: showVoidBtn ? "inline-flex" : "none",
              marginRight: "5px",
               fontSize:"16px"
            }}
            color="primary"
            className={classes.button}
            onClick={() => {
              setVoidTransactionId(transactionLongId);
              setOpenVoidPopup(true);
            }}
          >
            Void
          </Button>
          {/* --------------------------------- */}
          <Button
            variant="contained"
            id="voidBtn"
            style={{
              textTransform: "none",
              display: showVoidReturnBtn ? "inline-flex" : "none",
               fontSize:"16px"
            }}
            color="primary"
            className={classes.button}
            onClick={() => {
              setVoidReturnTransId(transactionLongId);
              setVoidReturnPopup(true);
            }}
          >
            Void Return
          </Button>
          {/* --------------------------------------- */}
          {refundBtnText == "Refunded" ? (
            <Button
              id="grayBgBtn"
              variant="contained"
              style={{
                cursor: "not-allowed",
                background: "#8F95A3 !important",
                color: "#FFFFFF",
                textTransform: "none",
                fontSize: "16px",
              }}
              color="primary"
              className={classes.grayBg}
            >
              {refundBtnText}
            </Button>
          ) : (
            <Button
              variant="contained"
              id="refundBtn"
              style={{
                textTransform: "none",
                display: showRefundBtn ? "inline-flex" : "none",
                fontSize: "16px",
              }}
              color="primary"
              className={classes.button}
              onClick={() => {
                setOpenReturnTypePopup(true);
              }}
            >
              {refundBtnText}
            </Button>
          )}
          <Button
            variant="contained"
            id="lightGreenBtn"
            style={{
              marginLeft: "5px",
              textTransform: "none",
            }}
            color="primary"
            className={classes.button}
            onClick={() => {
              // setEcrRefNo(ecrref_num);
              // setAmt(eachTransaction.amount);
              setOpenSendReceipt(true);
            }}
          >
            <img src={"/email.png"} style={{ width: "22px" }}></img>
          </Button>
          <Button
            variant="contained"
            id="lightGreenBtn"
            style={{
              marginLeft: "5px",
              textTransform: "none",
            }}
            color="primary"
            className={classes.button}
            onClick={() => {
              setOpenSMSReceipt(true);
            }}
          >
            <img src={"/sms.png"} style={{ width: "22px" }}></img>
          </Button>
          <Button
            id="lightGreenBtn"
            style={{
              marginLeft: "5px",
              textTransform: "none",
            }}
            onClick={() => {
              downloadPdf(returnEcrRefNo);
            }}
          >
            <img src={"/pdf.png"} style={{ width: "22px" }}></img>
            {/* <PictureAsPdfIcon /> */}
          </Button>{" "}
          
              {(token)?
          <Button
            id="lightGreenBtn"
            style={{
              marginLeft: "5px",
              textTransform: "none",
              fontSize: "16px",
              fontWeight:"Bold"
            }}
            onClick={(e) => {
              showCardHistory(e);
            }}
          >
            Card History
          </Button>: ""}
              
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>
                Transaction Information
              </h4>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <table style={{ width: "100%", tableLayout: "fixed" }}>
                    <tbody>
                      <tr>
                        <td>
                          <b>Amount: </b>${transAmount}
                        </td>
                        <td>
                          <b>Merchant: </b>
                          {businessName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Date: </b>
                          {transDeviceCreatedAt}
                        </td>
                        <td>
                          <b>Transaction Type: </b>
                          {transType}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Entry Type: </b>
                          {transcardEntryType}
                        </td>
                        <td>
                          <b>Transaction ID: </b>
                          {transactionLongId}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Payment Method: </b>
                          {transPaymentMethod}
                        </td>
                        <td>
                          <b>Response Code: </b>
                          {transResponseCode}
                        </td>
                      </tr>
                      {(ip)?<tr>
                        <td>
                          <b>IP Address: </b>
                          {ip}
                        </td>                        
                      </tr>:""}
                      
                    </tbody>
                  </table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Card Information</h4>
            </CardHeader>

            <CardBody>
              <table
                style={{ width: "100%", tableLayout: "fixed" }}
                id="creditCardInfoTable"
              >
                <tr>
                  <td>
                    <div style={{display:"flex"}}>
                      <div>
                        <b>Card Number: </b>
                        {transCardType == "AMERICAN EXPRESS" ||
                        transCardType == "AMEX" ? (
                          <span>
                            {ccBin}*****{transCardNumber}
                          </span>
                        ) : (
                          <span>
                            {ccBin}******{transCardNumber}
                          </span>
                        )}
                      </div>
                      <div>                    
                        <img
                          src={transCardTypeLogo}
                          style={{ width: "22px",marginLeft:"10px" }}
                        ></img>
                        </div>
                    </div>
                  </td>
                  <td>
                    <b>Card Expiration: </b>
                    {transCardExpMonth}/{transCardExpYear}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Card Type: </b>
                    {transCardType}{" "}
                  </td>
                  <td>
                    <b>Processor: </b>
                    {processorName}
                  </td>
                </tr>
                {processorName == "TSYS TransIT" ? (
                  <tr>
                    <td>
                      <div style={{ display: "ruby" }}>
                        <b>AVS Status: </b> {transitAvsStatus} -{" "}
                        {transitAvsStatusShort}{" "}
                        <div class="tooltip1">
                          <img
                            src="/paymnet_card_icon/help_blue.png"
                            id="avsHelpImg"
                            width="17"
                            height="17"
                            style={{
                              display: transitAvsStatus ? "block" : "none",
                              marginBottom: "-3px",
                              cursor: "pointer",
                              marginLeft: "7px",
                            }}
                          />
                          <span class="tooltipText1">
                            {transitAvsStatusLong}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "ruby" }}>
                        <b>CVV Status: </b> {transitCvvStatus} -{" "}
                        {transitCvvStatusShort}{" "}
                        <div class="tooltip2">
                          <img
                            src="/paymnet_card_icon/help_blue.png"
                            id="cvvHelpImg"
                            width="17"
                            height="17"
                            style={{
                              display: transitCvvStatus ? "block" : "none",
                              marginBottom: "-3px",
                              cursor: "pointer",
                              marginLeft: "7px",
                            }}
                          />
                          <span class="tooltipText2">
                            {transitCvvStatusLong}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                <tr>
                  <td>
                    <b>Auth Code: </b>
                    {transAuthCode}
                  </td>
                  <td>
                    <b>Currency: </b>
                    {openOrderCurrency}
                  </td>
                </tr>
                {processorName != "TSYS TransIT" ? (
                  <tr>
                    <td>
                      <b>Name on Card: </b>
                      {openOrderName}
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {/* <tr>
                  <td>
                    <b>CC BIN: </b>
                    {ccBin}
                  </td>
                  <td></td>
                </tr> */}
              </table>
            </CardBody>
          </Card>
        </GridItem>
        {processorName == "TSYS TransIT" ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>Billing Information</h4>
              </CardHeader>

              <CardBody>
                <table style={{ width: "100%", tableLayout: "fixed" }}>
                  <tr>
                    <td>
                      <b>Name on Card: </b>
                      {openOrderName}
                    </td>
                    <td>
                      <b>Street Address: </b>
                      {openOrderAddress}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>City: </b>
                      {openOrderCity}
                    </td>
                    <td>
                      <b>State: </b>
                      {openOrderState}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Zip/Postal Code: </b>
                      {openOrderZipCode}
                    </td>
                    <td>
                      <b>Country: </b>
                      {openOrderCountry}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Phone Number: </b>
                      {Helper.formatPhoneNumber(openOrderMobileNo)}
                    </td>
                    <td>
                      <b>Email Address: </b>
                      {openOrderEmail}
                    </td>
                  </tr>
                </table>
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          ""
        )}

        {/* Void Transaction confirm start */}
        <Dialog
          open={openVoidPopup}
          onClose={() => {
            setOpenVoidPopup(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to void this transaction?
          </DialogTitle>

          <DialogActions>
            <Button
              id="btnGreyCancel"
              onClick={() => {
                setVoidTransactionId("");
                setOpenVoidPopup(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Cancel
            </Button>
            <Button
              id="btnGreenAdd"
              onClick={() => {
                handelVoidTransaction();
                setOpenVoidPopup(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/*  Void Transaction confirm end  */}

        <Dialog
          open={openReturnTypePopup}
          onClose={() => {
            setOpenReturnTypePopup(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            How would you like to Refund?
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div>
                {showFullRefundBtn ? (
                  <Button
                    onClick={() => {
                      setOpenReturnTypePopup(false);
                      setOpenReturnPopup(true);
                    }}
                    id="btnGreenAdd"
                    color="primary"
                    style={{
                      width: "100%",
                      backgroundColor: "#ffa726",
                      color: "#FFFFFF",
                      marginRight: "20px",
                    }}
                  >
                    Full Refund
                  </Button>
                ) : (
                  <Button
                    id="grayBgBtn"
                    variant="contained"
                    style={{
                      cursor: "not-allowed",
                      background: "#8F95A3 !important",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                    color="primary"
                    className={classes.grayBg}
                  >
                    Full Refund
                  </Button>
                )}
              </div>
              <div>
                {showPartialRefundBtn ? (
                  <Button
                    onClick={() => {
                      setOpenReturnTypePopup(false);
                      setOpenPartialReturnPopup(true);
                    }}
                    id="btnGreenAdd"
                    color="primary"
                    style={{
                      width: "100%",
                      backgroundColor: "#ffa726",
                      color: "#FFFFFF",
                    }}
                  >
                    Partial Refund
                  </Button>
                ) : (
                  <Button
                    id="grayBgBtn"
                    variant="contained"
                    style={{
                      cursor: "not-allowed",
                      background: "#8F95A3 !important",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                    color="primary"
                    className={classes.grayBg}
                  >
                    Partial Refund
                  </Button>
                )}
              </div>
              <div>
                {showItemRefundBtn ? (
                  <Button
                    onClick={() => {
                      setOpenReturnTypePopup(false);
                      setOpenItemRefundPopup(true);
                      getTransactionItemList();
                    }}
                    id="btnGreenAdd"
                    color="primary"
                    style={{
                      width: "100%",
                      backgroundColor: "#ffa726",
                      color: "#FFFFFF",
                    }}
                  >
                    Itemwise Refund
                  </Button>
                ) : (
                  <Button
                    id="grayBgBtn"
                    variant="contained"
                    style={{
                      cursor: "not-allowed",
                      background: "#8F95A3 !important",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                    color="primary"
                    className={classes.grayBg}
                  >
                    Itemwise Refund
                  </Button>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {/* Full Return Transaction confirm start */}
        <Dialog
          open={openReturnPopup}
          onClose={() => {
            setOpenReturnPopup(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to refund this transaction?
          </DialogTitle>

          <DialogActions>
            <Button
              id="btnGreyCancel"
              onClick={() => {
                setTransITTransId(0);
                setOpenReturnPopup(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Cancel
            </Button>
            <Button
              id="btnGreenAdd"
              onClick={() => {
                handelReturnTransaction("Full Refund", transactionTotalAmt);
                setPartialRefundAmount(transactionTotalAmt);
                setOpenReturnPopup(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          id="itemRefundDialog"
          open={openItemRefundPopup}
          onClose={() => {
            setOpenItemRefundPopup(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Item Refund</DialogTitle>
          <DialogContent>
            <FormGroup>{orderItem}</FormGroup>
          </DialogContent>
          <DialogActions className={classes.partialrefundpopupbuttonsection}>
            <Button
              id="btnGreyCancel"
              onClick={() => {
                setOpenItemRefundPopup(false);
              }}
              color="primary"
              style={{
                width: "100%",
                backgroundColor: "#ffa726",
                color: "#FFFFFF",
              }}
            >
              Cancel
            </Button>
            <Button
              id="btnGreenAdd"
              onClick={(e) => {
                setOpenItemRefundPopup(false);
                handelReturnTransaction("refund_items", "0.00");
              }}
              color="primary"
              style={{
                width: "100%",
                backgroundColor: "#ffa726",
                color: "#FFFFFF",
              }}
            >
              Refund
            </Button>
          </DialogActions>
        </Dialog>

        {/* Send email receipt start */}
        <Dialog
          open={openSendReceipt}
          onClose={() => {
            setOpenSendReceipt(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Send Email Receipt</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              label="Enter Email Address"
              type="email"
              onChange={(e) => setSendEmail(e.target.value)}
              value={sendEmail}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              id="btnGreyCancel"
              onClick={() => {
                // setEcrRefNo(0);
                setSendEmail("");
                setOpenSendReceipt(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Cancel
            </Button>
            <Button
              id="btnGreenEdit"
              onClick={() => {
                sendEmailReceipt();
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>

        {/* Send email receipt end */}

        {/* Send SMS receipt start */}
        <Dialog
          open={openSMSReceipt}
          onClose={() => {
            setOpenSMSReceipt(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
          <DialogContent>
            <TextField
              margin="25px"
              id="name"
              label="Enter Mobile Number"
              type="text"
              onChange={(e) => setSendNumber(e.target.value)}
              value={Helper.formatPhoneNumber(sendNumber)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              id="btnGreyCancel"
              onClick={() => {
                setSendNumber("");
                setOpenSMSReceipt(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Cancel
            </Button>
            <Button
              id="btnGreenAdd"
              onClick={() => {
                sendSMSReceipt();
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>

        {/* Send SMS receipt end */}

        <Dialog
          open={openPartialReturnPopup}
          onClose={() => {
            setOpenPartialReturnPopup(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            How would you like to Refund?
          </DialogTitle>
          <DialogContent>
            <div>
              <h5 className={classes.partialrefundamnt}>
                Remaining Refund Amount: $
                {parseFloat(availableRefund).toFixed(2)}
              </h5>
              <FormControl sx={{ width: "100%" }}>
                {/* Amount from textfield */}
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  margin="full"
                  onChange={(e) => validatePartialRefundValue(e)}
                  value={parseFloat(returnEnterAmt).toFixed(2)}
                  label="Enter Amount"
                  style={{ width: "100%" }}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions className={classes.partialrefundpopupbuttonsection}>
            <Button
              id="btnGreyCancel"
              onClick={() => {
                setOpenPartialReturnPopup(false);
              }}
              color="primary"
              style={{
                width: "100%",
                backgroundColor: "#ffa726",
                color: "#FFFFFF",
              }}
            >
              Cancel
            </Button>
            <Button
              id="btnGreenAdd"
              onClick={() => {
                setOpenPartialReturnPopup(false);
                handelReturnTransaction("Partial Refund", returnEnterAmt);
                setOpenPartialReturnPopup(false);
              }}
              color="primary"
              style={{
                width: "100%",
                backgroundColor: "#ffa726",
                color: "#FFFFFF",
              }}
            >
              Partial Refund
            </Button>
          </DialogActions>
        </Dialog>

        {/*  Return Transaction confirm end  */}

        {/*void Return confirm start */}
        <Dialog
          open={voidReturnPopup}
          onClose={() => {
            setVoidReturnPopup(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to void return this transaction?
          </DialogTitle>

          <DialogActions>
            <Button
              id="btnGreyCancel"
              onClick={() => {
                setVoidReturnTransId("");
                setVoidReturnPopup(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Cancel
            </Button>
            <Button
              id="btnGreenAdd"
              onClick={() => {
                voidReturnTransIt(voidReturnTransId);
                setVoidReturnPopup(false);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/*void Return confirm end */}

        {/* <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Shipping Information</h4>
            </CardHeader>

            <CardBody>

            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
